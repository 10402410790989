<template>
  <Cond :where="where" @refresh="refresh">
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="id" label="id" width="80"> </el-table-column>
      <el-table-column prop="user_name" label="会员账号" width="180">
      </el-table-column>
      <el-table-column prop="op_type" label="类型">
        <template slot-scope="scope">
          <el-tag
            size="small"
            class="mgr10"
            v-if="scope.row.op_type == 1"
            type="success"
            effect="dark"
            >解绑shpee店铺</el-tag
          ><el-tag
            size="small"
            type="primary"
            effect="dark"
            v-else-if="scope.row.op_type == 2"
            >解绑lazada店铺</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="操作时间">
        <template slot-scope="scope">
          {{ $func.format(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="msg" label="备注">
        <template slot-scope="scope">
          <div v-html="getMsg(scope.row.msg)"></div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
  </Cond>
</template>
<script>
import Cond from "./memberopcond.vue";
export default {
  components: {
    Cond,
  },
  data() {
    return {
      url: {
        memberoplist: "/sysadminmember/memberoplist",
      },
      where: {
        user_name: "",
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    getMsg: function (msg) {
      var v1 = msg.substring(0, 6);
      var v2 = msg.substring(6);
      return (
        "<p class='f12' style='width:400px;'><span class='f-error bold' style='padding-right:10px;'>" +
        v1 +
        "</span>" +
        v2 +
        "</p>"
      );
    },
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.memberoplist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>